import * as Yup from 'yup';
// utils
import { isEmpty } from './preventDuplicateCustomers';
// ----------------------------------------------------------------------
export function EmptyConductorInfos(setValue, customer) {
    const idInfos = ['number', 'type', 'issueDate', 'expiryDate'];
    const info = ['firstName', 'lastName', 'dateOfBirth', 'nationalityId', 'address', 'phone'];
    return (info.map((item) => setValue(`${customer}[${item}]`, '')) &&
        idInfos.map((item) => setValue(`${customer}[customerIdentification][0][${item}]`, '')) &&
        idInfos.map((item) => setValue(`${customer}[customerIdentification][1][${item}]`, '')));
}
export function isCustomerBanned(customerId, customer, firstConductor, secondConductor) {
    const customerBanned = customer?.id?.toString() === customerId?.toString() &&
        Boolean(customer.agencyCustomers[0]?.banned);
    const firstConductorBanned = firstConductor?.id?.toString() === customerId?.toString() &&
        Boolean(firstConductor.agencyCustomers[0]?.banned);
    const secondConductorBanned = secondConductor?.id?.toString() === customerId?.toString() &&
        Boolean(secondConductor.agencyCustomers[0]?.banned);
    return customerBanned || firstConductorBanned || secondConductorBanned;
}
export function setCustomerDrivingLicense(name, setValue) {
    if (name === 'customer[customerIdentification][1][number]' ||
        name === 'customer[customerIdentification][0][number]')
        setValue('customer[customerIdentification][1][type]', 'driving_license');
    if (name === 'firstConductor[customerIdentification][1][number]')
        setValue('firstConductor[customerIdentification][1][type]', 'driving_license');
    if (name === 'secondConductor[customerIdentification][1][number]')
        setValue('secondConductor[customerIdentification][1][type]', 'driving_license');
}
export function conductorValidation(conductor, isEditConductor, isCustomer) {
    return Yup.object().shape({
        firstName: isEditConductor === true
            ? Yup.string()
                .trim()
                .required(`${conductor} first name is required`)
                .min(3, `${conductor} first name must be at least 3 characters`)
            : Yup.string()
                .trim()
                .optional()
                .when('field', {
                is: (val) => val !== undefined && val !== '',
                then: Yup.string().min(3, `${conductor} first name must be at least 3 characters`),
            }),
        lastName: isEditConductor === true
            ? Yup.string()
                .trim()
                .required(`${conductor} last name is required`)
                .min(3, ` ${conductor} last name must be at least 3 characters`)
            : Yup.string()
                .trim()
                .optional()
                .when('field', {
                is: (val) => val !== undefined && val !== '',
                then: Yup.string().min(3, ` ${conductor} last name must be at least 3 characters`),
            }),
        dateOfBirth: isEditConductor === true
            ? Yup.date()
                .transform((value, originalValue) => (originalValue === '' ? null : new Date(value)))
                .test('age', `${conductor} must be at least 18 years old`, (value) => {
                if (!value)
                    return false;
                const currentDate = new Date();
                const birthDate = new Date(value);
                const age = currentDate.getFullYear() - birthDate.getFullYear();
                const monthDiff = currentDate.getMonth() - birthDate.getMonth();
                if (monthDiff < 0 ||
                    (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
                    return age - 1 >= 18;
                }
                return age >= 18;
            })
                .typeError(`${conductor} date of birth is required`)
                .required(`${conductor} date of birth is required`)
            : Yup.date()
                .transform((value) => (value !== undefined ? null : new Date(value)))
                .nullable()
                .default(null)
                .optional()
                .typeError(''),
        nationalityId: isEditConductor === true
            ? Yup.string().nullable().trim().required('Nationality is required')
            : Yup.string().nullable().trim().optional(),
        address: isEditConductor === true
            ? Yup.string()
                .trim()
                .min(4, `${conductor} address is required`)
                .required(`${conductor} address is required`)
            : Yup.string()
                .trim()
                .optional()
                .when('field', {
                is: (val) => val !== undefined && val !== '',
                then: Yup.string().min(4, `${conductor} address must be at least 4 characters`),
            }),
        phone: isEditConductor === true
            ? Yup.string()
                .nullable()
                .trim()
                .required(`${conductor} phone is required`)
                .min(8, `${conductor} phone number must be at least 8 number`)
            : Yup.string()
                .trim()
                .optional()
                .when('field', {
                is: (val) => val !== undefined && val !== '',
                then: Yup.string().min(8, `${conductor} number must be at least 8 number`),
            }),
        customerIdentification: Yup.array().of(Yup.object().shape({
            type: isEditConductor === true
                ? Yup.string().nullable().trim().required('Identification Type Is Required')
                : Yup.string().nullable().trim().optional(),
            number: (isCustomer === true &&
                isEditConductor === true &&
                Yup.string().when('type', {
                    is: (type) => isEditConductor === true &&
                        isCustomer === true &&
                        type?.includes('driving_license'),
                    then: Yup.string().nullable().trim().optional(),
                    otherwise: Yup.string().trim().nullable().required('Please provide id number'),
                })) ||
                (isEditConductor === true &&
                    !isCustomer &&
                    Yup.string().trim().nullable().required('Please provide id number')) ||
                Yup.string().trim().nullable().optional(),
            issueDate: Yup.date()
                .nullable()
                .transform((value, originalValue) => (originalValue === '' ? null : value))
                .default(null)
                .optional()
                .typeError(''),
            expiryDate: Yup.date()
                .nullable()
                .transform((value, originalValue) => (originalValue === '' ? null : value))
                .default(null)
                .optional()
                .typeError(''),
        })),
    });
}
export function placeObjectAsSecond(array, targetType) {
    const drivingLicenseObject = array?.find((item) => item?.type?.includes(targetType));
    if (!drivingLicenseObject) {
        return array;
    }
    const newArray = array.filter((item) => item !== drivingLicenseObject);
    newArray.splice(1, 0, drivingLicenseObject);
    return newArray;
}
export function CustomerInitialValue(isEdit, customer) {
    return {
        firstName: isEdit ? customer?.firstName : '',
        lastName: isEdit ? customer?.lastName : '',
        dateOfBirth: isEdit ? customer?.dateOfBirth : '',
        nationalityId: isEdit ? customer?.nationalityId : '',
        address: isEdit ? customer?.address : '',
        phone: isEdit ? customer?.phone : '',
        customerIdentification: isEdit &&
            isEmpty(placeObjectAsSecond(customer?.customerIdentifications, 'driving_license')?.[0].number)
            ? placeObjectAsSecond(customer?.customerIdentifications, 'driving_license')?.map((el, index) => ({
                type: index === 1 ? 'driving_license' : el?.type?.replace(/\s+/g, '_'),
                number: el?.number,
                issueDate: el?.issueDate,
                expiryDate: el?.expiryDate,
            }))
            : [
                { type: '', number: '', issueDate: '', expiryDate: '' },
                { type: 'driving_license', number: '', issueDate: '', expiryDate: '' },
            ],
    };
}
export function structureCustomerIdentificationArray(createReservationData, values, customerType) {
    createReservationData[customerType].customerIdentification = placeObjectAsSecond(values[customerType]?.customerIdentification, 'driving license')?.filter((item) => !(item?.type?.includes('driving_license') && !(isEmpty(item?.number) || item?.number === 0)));
    if (!isEmpty(createReservationData[customerType].customerIdentification?.[1]?.number) ||
        createReservationData[customerType].customerIdentification?.[1]?.number === 0) {
        delete createReservationData[customerType].customerIdentification?.[1]?.type;
    }
    createReservationData[customerType].customerIdentifications = createReservationData[customerType].customerIdentification
        ?.map((obj) => Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, String(value)])))
        .map((obj) => Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== 'null' && value !== 'undefined')));
    delete createReservationData[customerType].customerIdentification;
}
